import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Switch from 'react-switch';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { OperatorIcon } from '../../modules/cs/common/components/operator-icon';
import { IOperatorNode } from '../../modules/common/model/graphql-types';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './characters.scss';

interface IOperatorNodes {
  nodes: IOperatorNode[];
}

interface IOperatorEntry {
  allOperators: IOperatorNodes;
}

interface IProps {
  data: IOperatorEntry;
}

const OperatorsPage: React.FC<IProps> = ({ data }) => {
  const FILTER_STORAGE_KEY = 'operators-filter';
  const OPTS_STORAGE_KEY = 'operators-opts';
  const GLOBAL_OPTS_STORAGE_KEY = 'global-opts';

  const isBrowser = typeof window !== 'undefined';
  let currentOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(OPTS_STORAGE_KEY))
    : {};
  if (!currentOpts) {
    currentOpts = {};
  }
  let currentGlobalOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(GLOBAL_OPTS_STORAGE_KEY))
    : {};
  if (!currentGlobalOpts) {
    currentGlobalOpts = {};
  }

  const [visibleOperators, setVisibleOperators] = useState(
    data.allOperators.nodes
  );
  const [activeFilters, setActiveFilters] = useState(
    isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY))
      : {} || {}
  );
  const [sortOption, setSortOption] = useState(currentOpts.sort || null);
  const [region, setRegion] = useState(currentGlobalOpts.region || null);
  const [showUnreleased, setShowUnreleased] = useState(
    currentOpts.unreleased || false
  );

  const filterConfig = {
    filters: [
      { key: 'searchText', type: 'search', placeholder: 'Search operators...' },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'N', value: 'n' },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' }
        ]
      },
      { key: 'reset', type: 'reset' }
      // { key: 'copy-to-clipboard', type: 'copy-to-clipboard' },
    ],
    defaults: isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY)) || {}
      : {}
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allOperators.nodes;

    if (showUnreleased) {
      filtered = filtered.filter(
        (operator) =>
          operator.regions.indexOf(region) === -1 &&
          operator.regions.indexOf('KR') !== -1
      );
    }
    if (region && !showUnreleased) {
      filtered = filtered.filter(
        (operator) => operator.regions.indexOf(region) > -1
      );
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (operator) =>
            operator.fullName
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (operator) =>
            operator.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
    }
    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.fullName < b.fullName ? 1 : -1));
    } else if (sortOption === 'unitid') {
      filtered = filtered.sort((a, b) => (a.unitId > b.unitId ? 1 : -1));
    } else if (sortOption === 'pvpRating') {
      filtered = filtered.sort((a, b) =>
        a.pvpScoreNumber < b.pvpScoreNumber ? 1 : -1
      );
    } else if (sortOption === 'pveRating') {
      filtered = filtered.sort((a, b) =>
        a.pveScoreNumber < b.pveScoreNumber ? 1 : -1
      );
    }
    if (isBrowser) {
      window.sessionStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify(activeFilters || {})
      );
      const newOpts = {
        sort: sortOption,
        unreleased: showUnreleased
      };
      window.sessionStorage.setItem(
        OPTS_STORAGE_KEY,
        JSON.stringify(newOpts || {})
      );
      const newGlobalOpts = {
        region: region
      };
      window.sessionStorage.setItem(
        GLOBAL_OPTS_STORAGE_KEY,
        JSON.stringify(newGlobalOpts || {})
      );
    }

    setVisibleOperators(filtered);
  }, [
    activeFilters,
    data.allOperators.nodes,
    sortOption,
    region,
    showUnreleased,
    isBrowser
  ]);

  // SEA needs to be the first on this list since it's the default region
  const serverOptions = [
    { value: 'KR', label: 'KR' },
    { value: 'Global', label: 'Global' }
  ];

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' },
    { value: 'unitid', label: 'Sort by Unit ID' },
    { value: 'pvpRating', label: 'Sort by PVP Rating' },
    { value: 'pveRating', label: 'Sort by PVE Rating' }
  ];

  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  const regionChangeHandler = (e) => {
    if (e.value === 'KR') {
      setShowUnreleased(false);
    }
    setRegion(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  let defaultRegion = region
    ? serverOptions.find((opt) => opt.value === region)
    : null;
  if (defaultRegion === null) {
    defaultRegion = serverOptions[0];
    setRegion(defaultRegion.value);
  }

  const unreleasedChangeHandler = (e) => {
    setShowUnreleased(e);
  };

  return (
    <DashboardLayout className={'generic-page characters-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>Operators</li>
      </ul>
      <div className="page-header ">
        <div className="page-icon">
          <StaticImage
            src="../../images/counterside/categories/category_operators.png"
            alt="Operators"
          />
        </div>
        <div className="page-details">
          <h1>Operators</h1>
          <h2>List of operators available in Counter Side.</h2>
          <p>
            Last updated: <strong>06/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Operators"
        subtitle={
          <span className="additional-text">
            Showing {visibleOperators.length} operator
            {visibleOperators.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar ">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Configure</Accordion.Header>
          <Accordion.Body>
            <p>
              Use the configuration options below to adjust the characters
              you're seeing.
            </p>
            <div className={`configuration-container`}>
              <div className="server">
                <span>Region</span>
                <Select
                  options={serverOptions}
                  className="custom-dropdown"
                  isSearchable={false}
                  onChange={regionChangeHandler}
                  defaultValue={defaultRegion}
                  classNamePrefix="custom-dropdown-inner"
                />
                <div className="switch-unreleased">
                  <Switch
                    checked={showUnreleased}
                    onChange={unreleasedChangeHandler}
                    disabled={region === 'KR'}
                    onColor="#009EEC"
                    offColor="#484950"
                    className="switch"
                  />
                  <span className="text">Show unreleased</span>
                </div>
              </div>
              <div className="sorting">
                <span>Sorting</span>
                <Select
                  options={sortingOptions}
                  className="custom-dropdown order-2 order-md-2"
                  isSearchable={false}
                  onChange={sortChangeHandler}
                  defaultValue={defaultSortOption}
                  classNamePrefix="custom-dropdown-inner"
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="employees-container">
        {visibleOperators.map((operator, index) => {
          return (
            <Card className="avatar-card" key={index}>
              <OperatorIcon operator={operator} showLabel enablePopover />
            </Card>
          );
        })}
      </div>
      {visibleOperators.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default OperatorsPage;

export const Head: React.FC = () => (
  <Seo
    title="Operators | Counter Side | Prydwen Institute"
    description="List of all Counter Side Operators available on the KR and Global servers."
    game="cs"
  />
);

export const pageQuery = graphql`
  query {
    allOperators: allContentfulOperator(
      sort: { fields: shortName, order: ASC }
    ) {
      nodes {
        id
        unitId
        fullName
        slug
        shortName
        regions
        rarity
        pvpScore
        pveScore
        pveScoreNumber
        pvpScoreNumber
        showNewTag
        areTranslationsUnnoficial
        areRatingsPending
        smallAvatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
